*,
*:before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  color: $monti-black;
  overflow-x: hidden;
}

body {
  font-family: $fontFamily;
}
img {
  max-width: 100%;
}
#root {
}
.title-oport {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 94%;

  /* or 19px */
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #000000;
  padding: 0;
  margin: 0;
}

.btn-black {
  color: #fff;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  min-width: 300px;
  background-color: #35a84e;

  border: 2px solid #35a84e;

  &.active {
    background-color: #35a84e;
    color: #fff;
  }

  &.disabled {
    background-color: #ccc !important;
    color: #000 !important;
    border: 2px solid #ccc !important;
    pointer-events: none !important;
  }
}
.btn-black:hover,
.btn-black:focus,
.btn-black:active {
  background-color: #125f23;

  border-color: #125f23;
  color: white;
  font-weight: bold;
}

.txt-seguinos {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 18.6667px;
  line-height: 22px;
  color: #fff !important;
}

.msgRegistrado {
  position: absolute;
  z-index: 99999;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("../img/okbg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  margin-top: 80px;
  padding-top: 50px;
}

.bgresto {
  background-color: #1400f8;
  height: 100%;
}

.form-marca-blanca {
  @media (min-width: 768px) {
    width: 325px;
    margin: 0 auto;
  }
}

.barra_superior {
  width: 100%;
  height: 8px;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;

  .progress {
    border-radius: 0 !important;
    height: 8px;
  }
}

.slider-marca {
  @media (min-width: 768px) {
    max-height: 600px;
  }
}
