.intro {
  h1 {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;

    /* identical to box height */

    color: #000000;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 30px;
      line-height: 35px;

      span {
        font-weight: 900;
      }
    }
  }

  h2 {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;

    text-transform: uppercase;

    color: #000000;
    text-align: left;
  }

  h3 {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;

    text-transform: uppercase;

    color: #000000;
    text-align: left;
  }
}

.listado-marcas {
  .box {
    width: 246px;
    height: 296px;
    margin-bottom: 25px;

    @media (max-width: 768px) {
      width: 396px;
      height: 112px;
    }

    &.ford {
      background-image: url("../../img/marcas/boxes/ford_on.png");
      background-size: cover;
      opacity: 1;
      transition: 0.3s;

      @media (max-width: 768px) {
        background-image: url("../../img/marcas/boxes/mobile/ford.png");
      }
    }

    &.ford:hover {
      background-image: url("../../img/marcas/boxes/ford_off.png");
      opacity: 0.8;

      @media (max-width: 768px) {
        background-image: url("../../img/marcas/boxes/mobile/ford.png");
      }
    }

    &.fiat {
      background-image: url("../../img/marcas/boxes/fiat_on.png");
      background-size: cover;
      opacity: 1;
      transition: 0.3s;

      @media (max-width: 768px) {
        background-image: url("../../img/marcas/boxes/mobile/fiat.png");
      }
    }

    &.fiat:hover {
      background-image: url("../../img/marcas/boxes/fiat_off.png");
      opacity: 0.8;

      @media (max-width: 768px) {
        background-image: url("../../img/marcas/boxes/mobile/fiat.png");
      }
    }

    &.jeep {
      background-image: url("../../img/marcas/boxes/jeep_on.png");
      background-size: cover;
      opacity: 1;
      transition: 0.3s;
      @media (max-width: 768px) {
        background-image: url("../../img/marcas/boxes/mobile/jeep.png");
      }
    }

    &.jeep:hover {
      background-image: url("../../img/marcas/boxes/jeep_off.png");
      opacity: 0.8;
      @media (max-width: 768px) {
        background-image: url("../../img/marcas/boxes/mobile/jeep.png");
      }
    }
    &.peugeot {
      background-image: url("../../img/marcas/boxes/peugeot_on.png");
      background-size: cover;
      opacity: 1;
      transition: 0.3s;
      @media (max-width: 768px) {
        background-image: url("../../img/marcas/boxes/mobile/peugeot.png");
      }
    }

    &.peugeot:hover {
      background-image: url("../../img/marcas/boxes/peugeot_off.png");
      opacity: 0.8;
      @media (max-width: 768px) {
        background-image: url("../../img/marcas/boxes/mobile/peugeot.png");
      }
    }
    &.hyundai {
      background-image: url("../../img/marcas/boxes/hyundai_on.png");
      background-size: cover;
      opacity: 1;
      transition: 0.3s;
      @media (max-width: 768px) {
        background-image: url("../../img/marcas/boxes/mobile/hyundai.png");
      }
    }

    &.hyundai:hover {
      background-image: url("../../img/marcas/boxes/hyundai_off.png");
      opacity: 0.8;
      @media (max-width: 768px) {
        background-image: url("../../img/marcas/boxes/mobile/hyundai.png");
      }
    }
  }
}
